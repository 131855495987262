<template>
  <div class="row" >
    <div class="col-md-12">
      <dashboard-box :title="$t('general.download_pdf')">
        <template v-slot:preview>
            <div class="row py-3" v-if="downloaded" id="pdf-holder">
              <div class="col-lg-12 row">
                <div class="col-lg-2 col-md-2 col-sm-12">
                  <qrcode-vue
                    :value="sample.code_link"
                    :size="100"
                    renderAs="svg"
                  ></qrcode-vue>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12 row">
                    <div class="col-12 d-flex justify-content-center">
                      <h4 class="text-center my-3 d-inline-block button-border"
                      >
                        <i class="fas fa-desktop text-gray-800"></i>
                        {{ $t('editSample.information_defined_by_system') }}
                      </h4>
                    </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <p><strong>{{$t('editSample.product_name')}}</strong> : {{ sample.name}}</p>
                        <p><strong>{{$t('editSample.meeting_number')}}</strong> : {{ meeting == null ? '-' : meeting.id}}</p>
                        <p><strong>{{$t('editSample.order_date')}}</strong> : {{ sample.order_date | momentDateFormat}}</p>
                      </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <p><strong>{{$t('editSample.meeting_name')}}</strong> : {{ meeting == null ? '-' : meeting.name }}</p>
                      <p><strong>{{$t('editSample.collection_number')}}</strong> : {{ sample.collection_number }}</p>
                      <p><strong>{{$t('editSample.client_name')}}</strong> : {{ sample.company.name }}</p>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12">
                  <img :src="sample.image" class="w-100px h-100px object-cover float-right rounded-lg" alt="">
                </div>
              </div>

              <div class="col-12 row mt-5">
                <div class="col-12 d-flex justify-content-center">
                  <h4 class="text-center my-3 d-inline-block button-border">
                    <i class="fas fa-desktop text-gray-800"></i>
                    {{ $t('editSample.information_entry') }}
                  </h4>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <i class="fas fa-user"></i>
                  <h6 class="pl-2 text-success font-weight-normal"> {{ $t('editSample.responsible_person_for_current_sample')}} : <strong>Ahmet</strong></h6>
                </div>
                <div class="col-12 row">
                  <div class="col-12 my-3">
                    <h4 class="pl-2 button-border d-inline-block">
                      <i class="fas fa-tshirt"></i>
                      {{ $t('editSample.collection_description')}}</h4>
                  </div>
                  <div class="col-12 row d-flex justify-content-center">
                    <div class="col-3">
                      <p><strong>{{ $t('editSample.model_no')}}</strong> : {{ sample.model_number}}</p>
                    </div>
                    <div class="col-3">
                      <p><strong>{{ $t('editSample.model_name')}}</strong> : {{ sample.model_name }}</p>
                    </div>
                    <div class="col-3">
                      <p><strong>{{ $t('editSample.product_for')}}</strong> : {{  sample.to_who }}</p>
                    </div>
                    <div class="col-3">
                      <p><strong>{{ $t('editSample.model_description')}}</strong> : {{ sample.model_description }}</p>
                    </div>
                    <div class="col-3">
                      <p><strong>{{ $t('editSample.deadline')}}</strong> : {{ sample.deadline | momentDateFormat }}</p>
                    </div>

                  </div>
                </div>

                <div class="col-12 row">
                  <div class="col-12 my-3">
                    <h4 class="pl-2 button-border d-inline-block">
                      <i class="fas fa-tshirt"></i>
                      {{ $t('editSample.color_and_size_description')}}</h4>
                  </div>
                  <div class="col-12 row d-flex justify-content-center" v-for="value in sample.sample_variations">
                    <div class="col-3" v-if="value.color != null">
                      <p><strong>{{ $t('editSample.color')}}</strong> : {{ value.color }}</p>
                    </div>
                    <div class="col-3" v-if="value.size != null">
                      <p><strong>{{ $t('editSample.size')}}</strong> : {{ value.size.body_size_id }}</p>
                    </div>
                    <div class="col-3" v-if="value.amount != null">
                      <p><strong>{{ $t('editSample.amount')}}</strong> : {{ value.amount }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-12 row">
                  <div class="col-12 my-3">
                    <h4 class="pl-2 button-border d-inline-block">
                      <i class="fas fa-ring"></i>
                      {{ $t('editSample.product_description')}}</h4>
                  </div>
                  <div class="col-12 row d-flex justify-content-center">
                    <div class="col-4">
                      <p><strong>{{ $t('editSample.fabric_type')}}</strong> : {{ sample.fabric_type.id }}</p>
                    </div>
                    <div class="col-4">
                      <p><strong>{{ $t('editSample.width')}}</strong> : {{ sample.width }}</p>
                    </div>
                    <div class="col-4">
                      <p><strong>{{ $t('editSample.weight')}}</strong> : {{ sample.weight }}</p>
                    </div>
                    <div class="col-12">
                      <p><strong>{{ $t('editSample.fabric_composition')}} : </strong>
                        <template v-for="value in sample.fabric_composition_sample">
                          <span class="d-line-block mx-3"><strong>{{ value.fabric_composition.id }} : </strong> %{{ value.percent}}</span>
                        </template>
                      </p>
                    </div>

                    <div class="col-12 px-0 my-5">
                      <template v-for="(value, index) in sample.accessory_sample">
                        <div class="row col-12">
                          <div class="col-3">
                            <strong>{{ index + 1 }}. {{ $t('editSample.accessory') }}: <span class="text-success">{{ value.accessory.id}}</span></strong>
                          </div>
                          <div class="col-3">
                            <p><strong>{{ $t('editSample.variant')}} : </strong> {{ value.variant }}</p>
                          </div>
                          <div class="col-3">
                            <p><strong>{{ $t('editSample.consumption')}} : </strong> {{ value.consumption }}</p>
                          </div>
                          <div class="col-3">
                            <p><strong>{{ $t('editSample.unit')}} : </strong> {{ value.unit.id }}</p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="col-12 row">
                  <div class="col-12 d-flex justify-content-start" style="align-items: center">
                    <img src="https://picsum.photos/200" class="w-50px h-50px object-cover float-right" alt="" style="border-radius: 50%;">
                    <p class="ml-4 font-weight-bold font-size-lg">{{ sample.user.name }}</p>
                  </div>
                  <div class="col-12 mt-4">
                    {{ sample.note }}
                  </div>
                </div>
              </div>
            </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import QrcodeVue from 'qrcode.vue'

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import jsPDF from "jspdf";
import Vue from 'vue';
import $ from 'jquery';
import {GET_ITEM_DETAIL_BY_ID} from "@/core/services/store/REST.module";
import moment from 'moment'
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import VueHtml2Canvas from 'vue-html2canvas';

Vue.use(VueHtml2Canvas);

export default {
  name: "SamplePDF",
  components: {
    DashboardBox,
    QrcodeVue,
  },
  computed: {
    ...mapGetters({

    }),
  },
  data() {
    return {
      id: null,
      sample: null,
      meeting: null,
      downloaded: false,
      loading: true,
    };
  },
  methods: {
    ...mapMutations({}),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('general.sample'), route: "sample" },
      { title: this.$t('general.download_pdf') }
    ]);
    this.id = this.$route.params.id;
    if(!this.id){
      window.close();
    }
    let self = this;
    this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
      url: `api/samples/${this.id}`,
      acceptPromise: true
    }).then((result) => {
      if(result.status){
        self.sample = result.data;
        self.sample.deadline = moment(self.sample.deadline, LARAVEL_DATE_FORMAT);
        self.sample.order_date = moment(self.sample.deadline, LARAVEL_DATE_FORMAT);
        if(Number(self.sample.meeting_id) !== 0){
          self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: `api/meetings/${self.sample.meeting_id}`,
            acceptPromise: true
          }).then((meetingResult) => {
            if(meetingResult.status){
              self.meeting = meetingResult.data;
              self.loading = false;
              self.downloaded = true;
              setTimeout(function(){
                self.downloadPdf('pdf-holder', self.sample.name)
              }, 1000);
            }
          })
        }else{
          self.loading = false;
          self.downloaded = true;
          setTimeout(function(){
            self.downloadPdf('pdf-holder', self.sample.name)
          }, 1000);
        }
      }
    })
  }
}
</script>

<style scoped>
.button-border{
  border-bottom: 1px solid rgba(150, 150, 150, 0.4);
}
</style>
